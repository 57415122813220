





































































import { Vue, Component, Ref } from 'vue-property-decorator'
import { parse } from 'papaparse'
import moment from 'moment'
import ListView from '@/components/ListView.vue'
import DateSelect from '@/components/DateSelect.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import CSVExportBtn from '@/components/CSVExport.vue'
import { Shop } from '@/types/shop'

const parseCSV = (file: File) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      error: err => {
        reject(err)
      },
      complete: results => {
        return resolve(results.data)
      },
    })
  })
}

const transformCSV = (data: string[][], shops: Shop[]) => {
  const header = data[0]
  if (!header) return []
  const shopCodeIdx = header.findIndex(x => String(x).match('コード'))
  const dateIdx = header.findIndex(x => String(x).match('日'))
  const memoIdx = header.findIndex(x => String(x).match('メモ'))
  const code2id: Map<string, Shop> = new Map()
  shops.forEach(shop => {
    code2id.set(shop.code, shop)
  })
  data = data.slice(1)
  return data.map(row => {
    const shop = code2id.get(row[shopCodeIdx])
    const date = moment(row[dateIdx])
    return {
      isValid: Boolean(shop) && date.isValid(),
      shop: shop?.id,
      shopCode: row[shopCodeIdx],
      shopName: shop?.name,
      date: date.isValid() ? date.format('YYYY-MM-DD') : '',
      memo: row[memoIdx] || '',
    }
  })
}

@Component({
  components: { ListView, DateSelect, ShopAutocomplete, CSVExportBtn },
})
export default class HolidayView extends Vue {
  @Ref() readonly listView!: ListView

  filterDefinition = {
    ordering: { type: String, default: '' },
    shop: { type: String, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
  }
  filter: null | Record<string, any> = null
  selected: number[] = []

  headers = [
    { text: '日付', value: 'usedDate', width: 100 },
    { text: '店コード', value: 'shopCode', width: 60 },
    { text: '店舗', value: 'shopName', width: 200 },
    { text: 'お客様番号', value: 'customerCode', width: 100 },
    { text: 'お客様氏名', value: 'customerName', width: 100 },

    { text: 'チケット名称', value: 'name' },
    { text: '使用枚数', value: 'usedQuantity', width: 150 },
  ]
  shops: Shop[] = []
  bulkData: {
    isValid: boolean
    shopCode: any
    shopName: any
    shop: any
    date: string
    memo: string
  }[] = []
  isUploading = false
  dialog = false
  get csvHeaders() {
    return CSVHeaders
  }
  created() {
    this.fetchShop()
  }
  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
    this.selected = []
  }
  async fetchShop(nextUrl?: string) {
    const { results, next } = await (nextUrl
      ? this.$api.http.get(nextUrl)
      : this.$api.shops().list())
    this.shops = this.shops.concat(results)
    if (next) this.fetchShop(next)
  }

  async onFileChanged(file: null | File) {
    console.log('onFileChanged', file)
    if (file) {
      const csvData = (await parseCSV(file)) as any[][]
      this.bulkData = transformCSV(csvData, this.shops)
    }
  }
  async upload() {
    this.isUploading = true
    try {
      await this.$api
        .holidays()
        .bulkUpload(this.bulkData.filter(x => x.isValid))
      this.bulkData = []
      this.$toast.success('アップロードに成功しました。')
      this.dialog = false
    } catch (err) {
      console.error(err)
      this.$toast.error('アップロードに失敗しました。')
    }
    this.isUploading = false
  }
}

const CSVHeaders = [
  // { key: 'id', label: 'チケットID' },
  { key: 'usedDate', label: '日付' },
  { key: 'shopCode', label: '店コード' },
  { key: 'shopName', label: '店舗名' },
  { key: 'customerCode', label: 'お客様番号' },
  { key: 'customerName', label: 'お客様氏名' },
  { key: 'group', label: 'チケットID' },

  { key: 'ecProductId', label: 'EC商品ID' },

  { key: 'name', label: 'チケット名称' },
  { key: 'usedQuantity', label: '使用枚数' },
  { key: 'price', label: '購入金額' },
  { key: 'quantity', label: '購入枚数' },

  { key: 'ticket', label: '購入ID' },
  { key: 'id', label: '使用ID' },
]
